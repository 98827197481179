import './terms.scss';

import React from 'react';
import Layout from 'components/Layout/Layout';

const mainClassName = 'terms';

const Terms = () => {
  return (
    <Layout>
      <div className={mainClassName}>
        <h2 className="section-title__title">Terms and Conditions</h2>


        <p>Last updated: June 7, 2022</p>


        <p>These Terms and Conditions ("Terms", "Terms and Conditions") govern your relationship with homeontour.com
          website and all its subdomains (the "Service") operated by Copernicus Software ("us", "we", or "our").</p>

        <p>Please read these Terms and Conditions carefully before using the Service.</p>

        <p>Your access to and use of the Service is based on your acceptance of and compliance with these Terms.
          These Terms apply to all visitors, users and others who access or use the Service.</p>

        <p>By accessing or using the Service you agree to be bound by these Terms and accept all legal consequences.
          If you do not agree to these terms and conditions, in whole or in part, please do not use the Service.</p>


        <h2>Subscriptions</h2>

        <p>Some parts of the Service are billed on a subscription basis ("Subscription(s)"). You will be billed in
          advance on a recurring and periodic basis ("Billing Cycle"). Billing cycles are set either on a monthly or
          annual basis, depending on the type of subscription plan you select when purchasing a Subscription.</p>

        <p>At the end of each Billing Cycle, your Subscription will automatically renew under the exact same
          conditions unless you cancel it or Copernicus Software cancels it. You may cancel your Subscription
          renewal either through your online account management page or by contacting Copernicus Software customer
          support team.</p>

        <p>A valid payment method, including Stripe, is required to process the payment for your Subscription. You
          shall provide Copernicus Software with accurate and complete billing information including full name,
          address, state, zip code, telephone number, and a valid payment method information. By submitting such
          payment information, you automatically authorize Copernicus Software to charge all Subscription fees
          incurred through your account to any such payment instruments.</p>

        <p>Should automatic billing fail to occur for any reason, Copernicus Software will issue an electronic
          invoice indicating that you must proceed manually, within a certain deadline date, with the full payment
          corresponding to the billing period as indicated on the invoice.</p>

        <p>In the event of cancelling the subscription any existing tours will be available for the period of 12 months and Copernicus Software will allow creation of backup copies of the content stored on the platform. </p>
    
        <h2>Free Trial</h2>

        <p>Copernicus Software may, at its sole discretion, offer a Subscription with a free trial for a limited
          period of time ("Free Trial").</p>

        <p>You may be required to enter your billing information in order to sign up for the Free Trial.</p>

        <p>If you do enter your billing information when signing up for the Free Trial, you will not be charged by
          Copernicus Software until the Free Trial has expired. On the last day of the Free Trial period, unless you
          cancelled your Subscription, you will be automatically charged the applicable Subscription fees for the
          type of Subscription you have selected.</p>

        <p>At any time and without notice, Copernicus Software reserves the right to (i) modify the terms and
          conditions of the Free Trial offer, or (ii) cancel such Free Trial offer.</p>


        <h2>Fee Changes</h2>

        <p>Copernicus Software, in its sole discretion and at any time, may modify the Subscription fees for the
          Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing
          Cycle.</p>

        <p>Copernicus Software will provide you with a reasonable prior notice of any change in Subscription fees to
          give you an opportunity to terminate your Subscription before such change becomes effective.</p>

        <p>Your continued use of the Service after the Subscription fee change comes into effect constitutes your
          agreement to pay the modified Subscription fee amount.</p>


        <h2>Refunds</h2>

        <p>Certain refund requests for Subscriptions may be considered by Copernicus Software on a case-by-case
          basis and granted in sole discretion of Copernicus Software.</p>


        <h2>Content</h2>

        <p>Our Service allows you to post, link, store, share and otherwise make available certain information,
          text, graphics, videos, or other material ("Content"). You are responsible for the Content that you post
          to the Service, including its legality, reliability, and appropriateness.</p>

        <p>By posting Content to the Service, you grant us the right and license to use, modify, publicly perform,
          publicly display, reproduce, and distribute such Content on and through the Service. You retain any and
          all of your rights to any Content you submit, post or display on or through the Service and you are
          responsible for protecting those rights. You agree that this license includes the right for us to make
          your Content available to other users of the Service, who may also use your Content subject to these
          Terms.</p>

        <p>In particular, we reserve the right to use submitted Content for promotional purposes including posting
          to our social media channels and other, we also retain the right to use the Content to demonstrate the
          capabilities of the platform.</p>

        <p>You represent and warrant that: (i) the Content is yours (you own it) or you have the right to use it and
          grant us the rights and license as provided in these Terms, and (ii) the posting of your Content on or
          through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or
          any other rights of any person.</p>

        <p>We reserve all rights to block or remove communications or materials that we determine to be: (a)
          abusive, defamatory, or obscene; (b) fraudulent, deceptive, or misleading; (c) in violation of a
          copyright, trademark or, other intellectual property right of another or; (d) offensive or otherwise
          unacceptable to us in our sole discretion.</p>

        <p>You acknowledge that, by providing you with the ability to view and distribute user-generated content on
          the Service, we are merely acting as a passive conduit for such distribution and is not undertaking any
          obligation or liability relating to any contents or activities on the Service.</p>


        <h2>Accounts</h2>

        <p>When you create an account with us, you must provide us information that is accurate, complete, and
          current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate
          termination of your account on our Service.</p>

        <p>You are responsible for safeguarding the password that you use to access the Service and for any
          activities or actions under your password, whether your password is with our Service or a third-party
          service.</p>

        <p>You agree not to disclose your password to any third party. You must notify us immediately upon becoming
          aware of any breach of security or unauthorized use of your account.</p>

        <p>You may not use as a username the name of another person or entity or that is not lawfully available for
          use, a name or trade mark that is subject to any rights of another person or entity other than you without
          appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.</p>


        <h2>Intellectual Property</h2>

        <p>The Service and all contents, including but not limited to text, images, graphics or code are the
          property of Copernicus Software and are protected by copyright, trademarks, database and other
          intellectual property rights. You may display and copy, download or print portions of the material from
          the different areas of the Service only for your own non-commercial use. Any other use is strictly
          prohibited and may violate copyright, trademark and other laws. These Terms do not grant you a license to
          use any trademark of Copernicus Software or its affiliates. You further agree not to use, change or delete
          any proprietary notices from materials downloaded from the Service.</p>


        <h2>Links To Other Web Sites</h2>

        <p>The Service may contain links to third-party web sites or services that are not owned or controlled by
          Copernicus Software.</p>

        <p>Copernicus Software has no control over, and assumes no responsibility for, the content, privacy
          policies, or practices of any third party web sites or services. You further acknowledge and agree that
          Copernicus Software shall not be responsible or liable, directly or indirectly, for any damage or loss
          caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or
          services available on or through any such websites or services.</p>

        <p>We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites
          or services that you visit.</p>

        <h2 id="casl">CASL</h2>

        <p>Copernicus Software complies with the Canadian Anti-Spam Legislation (CASL). Email enquiries send out
          by our users to the agents generally are CASL exempt as they constitute service enquiries.</p>

        <p>It is the sole responsibility of the user to ensure that the emails send out using the service
          are only service enquiries as Copernicus Software does not review the messages in anyway.</p>

        <p>More information about Canadian SPAM regulation can be found here: fightspam.gc.ca.</p>

        <h2>Termination</h2>

        <p>We may terminate or suspend access to our Service immediately, without prior notice or liability, for any
          reason whatsoever, including, without limitation, if you breach the Terms.</p>

        <p>All provisions of the Terms shall survive termination, including, without limitation, ownership
          provisions, warranty disclaimers, indemnity and limitations of liability.</p>

        <p>Upon termination, your right to use the Service will immediately cease. If you wish to terminate your
          account, you may simply discontinue using the Service.</p>


        <h2>Indemnification</h2>

        <p>You agree to indemnify, defend and hold harmless Copernicus Software, its principals, officers,
          directors, representatives, employees, contractors, licensors, licensees, suppliers and agents, from and
          against any claims, losses, damages, obligations, costs, actions or demands.</p>

        <p>These include but are not limited to: (a) legal and accounting fees resulting from your use of the
          Service; (b) your breach of any of these Terms; (c) anything you post on or upload to the Service; and (d)
          any activity related to your account. This includes any negligent or illegal conduct by you, any person or
          entity accessing the Service using your account whether such access is obtained via fraudulent or illegal
          means.</p>


        <h2>Limitation Of Liability</h2>

        <p>Copernicus Software, its directors, employees, partners, agents, suppliers, or affiliates, shall not be
          liable for any loss or damage, direct or indirect, incidental, special, consequential or punitive damages,
          including without limitation, economic loss, loss or damage to electronic media or data, goodwill, or
          other intangible losses, resulting from (i) your access to or use of the Service; (ii) your inability to
          access or use the Service; (iii) any conduct or content of any third-party on or related to the Service;
          (iv) any content obtained from or through the Service; and (v) the unauthorized access to, use of or
          alteration of your transmissions or content, whether based on warranty, contract, tort (including
          negligence) or any other claim in law, whether or not we have been informed of the possibility of such
          damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</p>


        <h2>Disclaimer And Non-Waiver of Rights</h2>

        <p>Copernicus Software makes no guarantees, representations or warranties of any kind as regards the website
          and associated technology. Any purportedly applicable warranties, terms and conditions are excluded, to
          the fullest extent permitted by law. Your use of the Service is at your sole risk. The Service is provided
          on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether
          express or implied, including, but not limited to, implied warranties of merchantability, fitness for a
          particular purpose, non-infringement or course of performance, except as provided for under the laws of
          any province in Canada. In such cases, the provincial law shall apply to the extent necessary.</p>

        <p>Copernicus Software its subsidiaries, affiliates, and its licensors do not warrant that a) the Service
          will function uninterrupted, secure or available at any particular time or location; b) any errors or
          defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the
          results of using the Service will meet your requirements.</p>

        <p>If you breach any of these Terms and Copernicus Software chooses not to immediately act, or chooses not
          to act at all, Copernicus Software will still be entitled to all rights and remedies at any later date, or
          in any other situation, where you breach these Terms. Copernicus Software does not waive any of its
          rights. Copernicus Software shall not be responsible for any purported breach of these Terms caused by
          circumstances beyond its control. A person who is not a party to these Terms shall have no rights of
          enforcement.</p>

        <p>You may not assign, sub-license or otherwise transfer any of your rights under these Terms.</p>

        <h2>Exclusions</h2>

        <p>As set out, above, some jurisdictions do not allow the exclusion of certain warranties or the exclusion
          or limitation of liability for consequential or incidental damages, so the limitations above may not apply
          to you. Provincial laws of Canada may apply to certain products and service provided.</p>


        <h2>Governing Law</h2>

        <p>These Terms shall be governed by, and interpreted and enforced in accordance with, the laws in the
          Province of Ontario and the laws of Canada, as applicable.</p>

        <p>If any provision of these Terms is held to be invalid or unenforceable by a court of competent
          jurisdiction, then any remaining provisions of these Terms will remain in effect. These Terms constitute
          the entire agreement between us regarding our Service, and supersede and replace any prior agreements,
          oral or otherwise, regarding the Service.</p>


        <h2>Changes</h2>

        <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision
          is material we will make reasonable efforts to provide at least 30 days' notice prior to any new terms
          taking effect. What constitutes a material change will be determined at our sole discretion.</p>

        <p>By continuing to access or use our Service after those revisions become effective, you agree to be bound
          by the revised terms. If you do not agree to the new terms, in whole or in part, please stop using the
          website and the Service.</p>


        <h2>Contact Us</h2>

        <p>If you have any questions about these Terms, please contact us at contact@homeontour.com</p>

      </div>
    </Layout>
  );
};

export default Terms;
